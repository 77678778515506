export default {
  methods: {
    GetShareURL: function (externalID) {
      return `${this.$store.state.frontURL}/show/${externalID}`;
    },
    GetSharePath: function (externalID) {
      return `/show/${externalID}`;
    },
    // uploaded, show, list
    ExecDirect: function (url) {
      if (this.execCopy(url, "direct-url-copy-button") === false) {
        return this.toastError("このブラウザは対応していません 😥");
      }
    },
    ExecShare: function (externalID) {
      const url = this.GetShareURL(externalID);
      if (this.execCopy(url, "share-url-copy-button") === false) {
        return this.toastError("このブラウザは対応していません 😥");
      }
    },
    execCopy: function (string, eleID) {
      let temp = document.createElement("div");
      temp.appendChild(document.createElement("pre")).textContent = string;
      let s = temp.style;
      s.position = "fixed";
      s.left = "-100%";
      document.body.appendChild(temp);
      document.getSelection().selectAllChildren(temp);
      let result = document.execCommand("copy");
      document.body.removeChild(temp);

      if (eleID !== "") {
        const button = document.getElementById(eleID);
        button.innerText = "コピーしました";
      }

      return result;
    },
  },
};
