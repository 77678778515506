import { createStore } from "vuex";
import axios from "axios";

const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

export const store = createStore({
  state: {
    FILE_URL: import.meta.env.VITE_FILE_URL,
    fileCount: 12, // /files?count=n
    deleted: false, // ファイルが削除されて /show が表示されたとき
    token: "",
    user: {},
    stage: import.meta.env.VITE_STAGE,
    frontURL: import.meta.env.VITE_FRONT_URL,
    isError: false,
    errorMsg: "",
    uploadedFiles: [],
    isLoginProcessing: true,
    isLoggedIn: false,
    isFree: true,
    isPremium: false,
    isBusiness: false,
    isAPIUser: false,
    encryption: false,
    email: "",
    email_verified: false,
    isCopyModal: false,
    modal: {
      premium: {
        open: false,
        title: "",
        description: "",
      },
      relogin: {
        open: false,
      },
    },
    usage: {
      count: 0,
      total_size: 0,
    },
    groups: [],
    loginInit: {},
    init: {
      info: {
        is_maintenance: false,
        news: {
          title: "",
          tags: "",
          url: "",
        },
      },
      data_usage_info: {
        count: 0,
      },
      setting: {
        free_user_capacity_limit: 0,
        free_limit_upload_size: 0,
        max_upload_size: 0,
      },
    },
    emailFile: {
      isModalOpen: false,
      email: null,
      file: null,
    },
    uploadSetting: {
      maxTotalSize: 0,
      maxLargeTotalSize: 0,
    },
    userUploadSetting: {
      enable_auto_delete: false,
      automatic_deletion_seconds: 0,
      enable_auto_encryption: false,
      enable_auto_password: false,
      password: "",
    },
  },
  mutations: {
    resetUploadedFiles(state) {
      state.uploadedFiles = [];
    },
    updateMaxFiles(state, value) {
      state.uploadSetting.maxFiles = value;
    },
    updateMaxTotalSize(state, value) {
      state.uploadSetting.maxTotalSize = value;
    },
    updateDeleted(state, value) {
      state.deleted = value;
    },
    updateLoginInit(state, value) {
      state.loginInit = value;
    },
    updateUserUploadSetting(state, payload) {
      state.userUploadSetting = payload;
    },
    updateSubscription(state, payload) {
      state.isFree = payload.isFree;
      state.isPremium = payload.isPremium;
      state.isBusiness = payload.isBusiness;
      state.isAPIUser = payload.isAPIUser;
    },
  },
  actions: {
    async fetchUserUploadSetting({ commit, state }) {
      try {
        const response = await client.get(`/user/setting`, {
          headers: { Authorization: `Bearer ${state.token}` },
        });
        commit("updateUserUploadSetting", response.data);
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    },
    async vuexSaveUserUploadSetting({ commit, state }, newSettings) {
      try {
        const response = await client.put(`/user/setting`, newSettings, {
          headers: { Authorization: `Bearer ${state.token}` },
        });
        commit("updateUserUploadSetting", response.data);
        return { success: true };
      } catch (error) {
        console.error("Failed to save settings:", error);
        return { success: false, error };
      }
    },
  },
});
